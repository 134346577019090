import React, { useMemo } from "react";

import { useOverviewContext } from "../../context";

import { InfoCard } from "~/components/InfoCard";
import { Typography } from "~/components/Typography";
import { Loading } from "~/components/Loading";

import Box from "@material-ui/core/Box";

import { useSingleCardStyles } from "../../common/styles";

const CreditScore = () => {
	const styles = useSingleCardStyles();
	const {
		loading,
		data: { creditScore },
	} = useOverviewContext();

	const content = useMemo(() => {
		if (loading) {
			return <Loading />;
		}

		return (
			<div>
				<Typography
					className={styles.quantity}
				>{`${creditScore}`}</Typography>
				<Typography>Average Credit Score</Typography>
			</div>
		);
	}, [creditScore, loading, styles.quantity]);

	return (
		<InfoCard loading={loading} title="Average Credit Score">
			<Box className={styles.content}>{content}</Box>
		</InfoCard>
	);
};

export default CreditScore;
