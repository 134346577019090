import React, { useMemo } from "react";

import { useOverviewContext } from "../../context";

import { InfoCard } from "~/components/InfoCard";
import { Typography } from "~/components/Typography";

import Box from "@material-ui/core/Box";

import { useSingleCardStyles } from "../../common/styles";
import { Loading } from "~/components/Loading";

const LeadsUsage = ({ type }: { type: "all" | "credit" | "trade" }) => {
	const styles = useSingleCardStyles();
	const {
		loading,
		data: { leads },
	} = useOverviewContext();

	const content = useMemo(() => {
		if (loading) {
			return <Loading />;
		}

		const name = type.charAt(0).toUpperCase() + type.slice(1);
		const title = type === "all" ? "Opened Leads" : `Opened ${name} Leads`;
		return (
			<div>
				<Typography
					className={styles.quantity}
				>{`${leads?.opened[type]}/${leads?.allTime[type]}`}</Typography>
				<Typography>{title}</Typography>
			</div>
		);
	}, [leads?.opened, leads?.allTime, loading, styles.quantity]);

	return (
		<InfoCard loading={loading}>
			<Box className={styles.content}>{content}</Box>
		</InfoCard>
	);
};

export default LeadsUsage;
