import { makeStyles } from "@material-ui/core/styles";

export const customStyles = makeStyles(() => ({
	content: {
		padding: 24,
		display: "flex",
		flexDirection: "column",
		flexWrap: "nowrap",
		height: "100%",
		"& .MuiTableCell-stickyHeader": {
			border: "none",
			borderBottom: "1px solid #CACACA",
		},
		"@media print": {
			minWidth: 1400,
		},
	},
	item: {
		"@media print": {
			pageBreakInside: "avoid",
		},
	},
	monthAndPrint: {
		width: "100%",
		display: "flex",
		gap: 8,
		alignItems: "center",
	},
	h2: {
		margin: 0,
	},
	companyName: {
		display: "none",
		width: "100%",
		justifyContent: "center",
		margin: "24px 0",
		"@media print": {
			display: "flex",
		},
	},
}));
