import React, { Fragment } from "react";

import { useOverviewContext } from "../../context";

import MultipleInfoCard from "../MultipleInfoCard";
import { PrimaryButton } from "~/components/Button";
import { Box } from "@material-ui/core";
import { Typography } from "~/components/Typography";

import { useStyles } from "./styles";

const Leads = () => {
	const {
		loading,
		data: { leads },
		isPreviousMonth,
		handlePreviousMonth,
	} = useOverviewContext();

	const Header = () => {
		const styles = useStyles();
		const buttonText = () => {
			if (isPreviousMonth) {
				return "See Current Month";
			}
			return " See Previous Month";
		};
		return (
			<Box className={styles.leadsOverviewHeader}>
				<Typography variant="caption">Leads</Typography>
				<PrimaryButton
					className={styles.button}
					onClick={() => handlePreviousMonth()}
				>
					{buttonText()}
				</PrimaryButton>
			</Box>
		);
	};

	const columns = [
		{
			title: "Total Leads",
			quantity: isPreviousMonth
				? leads?.previousMonth.totalLeads
				: leads?.monthToDate.totalLeads,
		},
		{
			title: "Verified Credit Leads",
			quantity: isPreviousMonth
				? leads?.previousMonth.verifiedCreditLeads
				: leads?.monthToDate.verifiedCreditLeads,
		},
		{
			title: "Sell / Trade Leads",
			quantity: isPreviousMonth
				? leads?.previousMonth.sellAndTradeLeads
				: leads?.monthToDate.sellAndTradeLeads,
		},
		{
			title: "ID Verifications",
			quantity: isPreviousMonth
				? leads?.previousMonth.idLeads
				: leads?.monthToDate.idLeads,
		},
		{
			title: "Test Drive Bookings",
			quantity: isPreviousMonth
				? leads?.previousMonth.testDriveBookings
				: leads?.monthToDate.testDriveBookings,
		},
		{
			title: "Full Application",
			quantity: isPreviousMonth
				? leads?.previousMonth.fullAppLeads
				: leads?.monthToDate.fullAppLeads,
		},
	];
	return (
		<Fragment>
			<MultipleInfoCard
				loading={loading}
				data={columns}
				title={<Header />}
			/>
		</Fragment>
	);
};

export default Leads;
