import { useAuth } from "@api/auth";
import { useUserActivity } from "@api/users";
import { useOverview } from "@api/overview";
import React, { createContext, useContext, useMemo, useState } from "react";

interface Source {
	url: string;
	count: number;
}

interface Data {
	estimatedRevenue: {
		quantity: number;
	};
	expenses: {
		subscriptionFee: number;
		verifiedApplicationFee: number;
	};
	leads: {
		opened: {
			all: number;
			credit: number;
			trade: number;
		};
		previousMonth: {
			totalLeads: number;
			nonVerifiedCreditLeads: number;
			verifiedCreditLeads: number;
			sellAndTradeLeads: number;
			idLeads: number;
			testDriveBookings: number;
			fullAppLeads: number;
			status: {
				newStatusLead: number;
				soldStatusLead: number;
				workingStatusLead: number;
				contactedStatusLead: number;
				appointmentBookedStatusLead: number;
			};
		};
		monthToDate: {
			totalLeads: number;
			nonVerifiedCreditLeads: number;
			verifiedCreditLeads: number;
			sellAndTradeLeads: number;
			idLeads: number;
			testDriveBookings: number;
			fullAppLeads: number;
			status: {
				newStatusLead: number;
				soldStatusLead: number;
				workingStatusLead: number;
				contactedStatusLead: number;
				appointmentBookedStatusLead: number;
			};
		};
		allTime: {
			all: number;
			credit: number;
			trade: number;
			totalLeads: number;
			nonVerifiedCreditLeads: number;
			verifiedCreditLeads: number;
			sellAndTradeLeads: number;
			idLeads: number;
			testDriveBookings: number;
		};
	};
	sources: {
		all: Source[];
		trade: Source[];
		credit: Source[];
	};
	social: {
		google: number;
		facebook: number;
	};
	userActivities: {
		username: string;
		lastLogin: string;
		logins: number;
	}[];
	creditScore: number;
}

interface IOverviewContextType {
	isPreviousMonth: boolean;
	handlePreviousMonth: () => void;
	error: any;
	loading: boolean;
	data: Data;
}

const defaultContext: IOverviewContextType = {
	error: "" as any,
	loading: false,
	data: {} as Data,
	isPreviousMonth: false,
	handlePreviousMonth: () => {
		console.log(/* dummy Fb */);
	},
};

const OverviewContext = createContext<IOverviewContextType>(defaultContext);

export const useOverviewContext = () => {
	const context = useContext(OverviewContext);
	if (!context) {
		throw new Error(
			"You cannot use OverviewContext from outside of its Provider",
		);
	}

	return context;
};

const reduceSources = (
	aggs: {
		keys?: Maybe<string[]>;
		distinctCount?: Maybe<{ id?: Maybe<string> }>;
	}[],
) => {
	// clean up data
	const mapped = aggs
		.map((agg) => ({
			url: agg.keys?.[0] || "",
			count: parseFloat(agg?.distinctCount?.id || "0"),
		}))
		.filter((agg) => !!agg.url);

	// sort desc
	mapped.sort((a, b) => b.count - a.count);

	// top 5
	return mapped.slice(0, 5);
};

const useOverviewContextValue = () => {
	const { selectedCompany } = useAuth();
	const overview = useOverview({ companyId: selectedCompany?.id || 0 });
	const activity = useUserActivity("", 0, 100000);

	const leads = useMemo(() => {
		// Autocorp DEMO Account
		if (selectedCompany?.id === 1151236668) {
			return {
				opened: {
					all: 1200,
					credit: 956,
					trade: 144,
				},
				previousMonth: {
					totalLeads: 57,
					nonVerifiedCreditLeads: 21,
					verifiedCreditLeads: 72,
					sellAndTradeLeads: 19,
					idLeads:
						overview?.data?.previousMonth?.idLeads?.totalCount || 0,
					testDriveBookings: 11,
					fullAppLeads: 3,
					status: {
						newStatusLead: 17,
						soldStatusLead: 5,
						workingStatusLead: 2,
						contactedStatusLead: 5,
						appointmentBookedStatusLead: 1,
					},
				},
				monthToDate: {
					totalLeads: 190,
					nonVerifiedCreditLeads: 25,
					verifiedCreditLeads: 75,
					sellAndTradeLeads: 19,
					idLeads:
						overview?.data?.monthToDate?.idLeads?.totalCount || 0,
					testDriveBookings: 11,
					fullAppLeads: 4,
					status: {
						newStatusLead: 17,
						soldStatusLead: 5,
						workingStatusLead: 2,
						contactedStatusLead: 5,
						appointmentBookedStatusLead: 1,
					},
				},
				allTime: {
					all: 1386,
					credit: 1021,
					trade: 156,
					totalLeads: 1386,
					nonVerifiedCreditLeads: 203,
					verifiedCreditLeads: 1005,
					sellAndTradeLeads: 156,
					idLeads: 140,
					testDriveBookings: 77,
				},
			};
		}

		return {
			opened: {
				all: overview?.data?.allTime?.openedLeads?.totalCount || 0,
				credit:
					overview?.data?.allTime?.openedCreditLeads?.totalCount || 0,
				trade:
					overview?.data?.allTime?.openedTradeLeads?.totalCount || 0,
			},
			previousMonth: {
				totalLeads:
					overview?.data?.previousMonth?.allLeads?.totalCount || 0,
				nonVerifiedCreditLeads:
					overview?.data?.previousMonth?.nonVerifiedCreditLeads
						?.totalCount || 0,
				verifiedCreditLeads:
					overview?.data?.previousMonth?.creditLeads?.totalCount || 0,
				sellAndTradeLeads:
					overview?.data?.previousMonth?.tradeLeads?.totalCount || 0,
				idLeads:
					overview?.data?.previousMonth?.idLeads?.totalCount || 0,
				testDriveBookings:
					overview?.data?.previousMonth?.driveBookings?.totalCount ||
					0,
				fullAppLeads:
					overview?.data?.previousMonth?.fullAppLeads?.totalCount ||
					0,
				status: {
					newStatusLead:
						overview?.data?.previousMonth?.newStatusLead
							?.totalCount || 0,
					soldStatusLead:
						overview?.data?.previousMonth?.soldStatusLead
							?.totalCount || 0,
					workingStatusLead:
						overview?.data?.previousMonth?.workingStatusLead
							?.totalCount || 0,
					contactedStatusLead:
						overview?.data?.previousMonth?.contactedStatusLead
							?.totalCount || 0,
					appointmentBookedStatusLead:
						overview?.data?.previousMonth
							?.appointmentBookedStatusLead?.totalCount || 0,
				},
			},
			monthToDate: {
				totalLeads:
					overview?.data?.monthToDate?.allLeads?.totalCount || 0,
				nonVerifiedCreditLeads:
					overview?.data?.monthToDate?.nonVerifiedCreditLeads
						?.totalCount || 0,
				verifiedCreditLeads:
					overview?.data?.monthToDate?.creditLeads?.totalCount || 0,
				sellAndTradeLeads:
					overview?.data?.monthToDate?.tradeLeads?.totalCount || 0,
				idLeads: overview?.data?.monthToDate?.idLeads?.totalCount || 0,
				testDriveBookings:
					overview?.data?.monthToDate?.driveBookings?.totalCount || 0,
				fullAppLeads:
					overview?.data?.monthToDate?.fullAppLeads?.totalCount || 0,
				status: {
					newStatusLead:
						overview?.data?.monthToDate?.newStatusLead
							?.totalCount || 0,
					soldStatusLead:
						overview?.data?.monthToDate?.soldStatusLead
							?.totalCount || 0,
					workingStatusLead:
						overview?.data?.monthToDate?.workingStatusLead
							?.totalCount || 0,
					contactedStatusLead:
						overview?.data?.monthToDate?.contactedStatusLead
							?.totalCount || 0,
					appointmentBookedStatusLead:
						overview?.data?.monthToDate?.appointmentBookedStatusLead
							?.totalCount || 0,
				},
			},
			allTime: {
				all: overview?.data?.allTime?.allLeads?.totalCount || 0,
				credit: overview?.data?.allTime?.creditLeads?.totalCount || 0,
				trade: overview?.data?.allTime?.tradeLeads?.totalCount || 0,
				totalLeads: overview?.data?.allTime?.allLeads?.totalCount || 0,
				nonVerifiedCreditLeads:
					overview?.data?.allTime?.nonVerifiedCreditLeads
						?.totalCount || 0,
				verifiedCreditLeads:
					overview?.data?.allTime?.creditLeads?.totalCount || 0,
				sellAndTradeLeads:
					overview?.data?.allTime?.tradeLeads?.totalCount || 0,
				idLeads: overview?.data?.allTime?.idLeads?.totalCount || 0,
				testDriveBookings:
					overview?.data?.allTime?.driveBookings?.totalCount || 0,
			},
		};
	}, [overview]);

	const creditScore = useMemo(() => {
		// Autocorp DEMO Account
		if (selectedCompany?.id === 1151236668) {
			return 679;
		}

		const total = overview.data?.allTime?.creditScores?.totalCount || 0;

		// calculate sum
		const sum =
			overview.data?.allTime?.creditScores?.nodes?.reduce(
				(agg, next) => agg + (next.avaApp?.creditScore || 0),
				0,
			) || 0;
		if (!sum) {
			return 0;
		}

		// calculate average
		const avg = sum / total;

		// round
		return Math.round(avg);
	}, [overview.data?.allTime?.creditScores?.nodes]);

	const userActivities = useMemo(() => {
		// reduce data by username
		const reduced =
			activity.data?.userActivities?.nodes?.reduce((agg, next) => {
				if (!agg[next.username]) {
					agg[next.username] = {
						username: next.username,
						lastLogin: next.createdAt,
						logins: 0,
					};
				}
				// count logins
				agg[next.username].logins += 1;
				return agg;
			}, {} as any) || {};
		// map to array
		const mapped: any[] = Object.values(reduced);

		// sort desc
		mapped.sort((a, b) => b.logins - a.logins);

		// top 5
		return mapped.slice(0, 5);
	}, [activity.data?.userActivities?.nodes]);

	const sources = useMemo(() => {
		// Autocorp DEMO Account
		if (selectedCompany?.id === 1151236668) {
			return {
				all: [
					{
						url: "https://fakedealer.com/vdp/1234567" || "",
						count: 1256,
					},
					{
						url: "https://fakedealer.com/vdp/1234567" || "",
						count: 1256,
					},
					{
						url: "https://fakedealer.com/vdp/1234567" || "",
						count: 1256,
					},
					{
						url: "https://fakedealer.com/vdp/1234567" || "",
						count: 1256,
					},
					{ url: "https://fakedealer.com/vlp" || "", count: 1256 },
				],
				credit: [
					{
						url: "https://fakedealer.com/vdp/1234567" || "",
						count: 1256,
					},
					{
						url: "https://fakedealer.com/vdp/1234567" || "",
						count: 1256,
					},
					{
						url: "https://fakedealer.com/vdp/1234567" || "",
						count: 1256,
					},
					{
						url: "https://fakedealer.com/vdp/1234567" || "",
						count: 1256,
					},
					{ url: "https://fakedealer.com/vlp" || "", count: 1256 },
				],
				trade: [
					{
						url: "https://fakedealer.com/vdp/1234567" || "",
						count: 1256,
					},
					{
						url: "https://fakedealer.com/vdp/1234567" || "",
						count: 1256,
					},
					{
						url: "https://fakedealer.com/vdp/1234567" || "",
						count: 1256,
					},
					{
						url: "https://fakedealer.com/vdp/1234567" || "",
						count: 1256,
					},
					{ url: "https://fakedealer.com/vlp" || "", count: 1256 },
				],
			};
		}

		// extract aggregates
		return {
			all: reduceSources(
				overview.data?.allTime?.sources?.groupedAggregates || [],
			),
			credit: reduceSources(
				overview.data?.allTime?.creditSources?.groupedAggregates || [],
			),
			trade: reduceSources(
				overview.data?.allTime?.tradeSources?.groupedAggregates || [],
			),
		};
	}, [overview.data?.allTime?.sources?.groupedAggregates]);

	const data = {
		estimatedRevenue: {
			quantity:
				leads.allTime.verifiedCreditLeads * 100 +
				leads.allTime.sellAndTradeLeads * 50 +
				leads.allTime.testDriveBookings * 150,
		},
		expenses: {
			subscriptionFee: 3475,
			verifiedApplicationFee: leads.allTime.verifiedCreditLeads * 7.95,
		},
		leads,
		sources,
		social: {
			google: 378,
			facebook: 120,
		},
		userActivities,
		creditScore,
	};

	const [isPreviousMonth, setPreviousMonth] = useState(false);

	const handlePreviousMonth = () => setPreviousMonth(!isPreviousMonth);

	return {
		error: "",
		loading: overview.loading || activity.loading,
		data,
		isPreviousMonth,
		handlePreviousMonth,
	};
};

export const OverviewProvider: React.FC = ({ children }) => {
	const contextValue = useOverviewContextValue();

	return (
		<OverviewContext.Provider value={contextValue}>
			{children}
		</OverviewContext.Provider>
	);
};
