import React from "react";

import { InfoCard } from "~/components/InfoCard";
import { ITableColumn, ITableRow, Table } from "~/components/Table";

import { useOverviewContext } from "../../context";

const ago = (time: number, interval: string) => time > 1 ? `${time} ${interval}s ago` : `${time} ${interval} ago`;

function timeAgo(date: string) {
	var seconds = Math.floor((+new Date() - +new Date(date)) / 1000);
  
	var interval = seconds / 31536000;
  
	if (interval > 1) {
	  return ago(Math.floor(interval), "year");
	}
	interval = seconds / 2592000;
	if (interval > 1) {
	  return ago(Math.floor(interval), "month");
	}
	interval = seconds / 86400;
	if (interval > 1) {
	  return ago(Math.floor(interval), "day");
	}
	interval = seconds / 3600;
	if (interval > 1) {
	  return ago(Math.floor(interval), "hour");
	}
	interval = seconds / 60;
	if (interval > 1) {
	  return ago(Math.floor(interval), "minute");
	}
	return ago(Math.floor(seconds), "second");
  }

export const mostActiveUsers: ITableColumn[] = [
	{
		label: "Most Active Users",
		property: "username",
		width: "auto",
	},
	{
		label: "Logged In",
		property: "lastLogin",
		width: "auto",
		cell: timeAgo
	},
	{
		label: "Logins",
		property: "logins",
		width: "auto",
	},
	// {
	// 	label: "Leads Opened",
	// 	property: "leadsOpened",
	// 	width: "auto",
	// },
];

const MostActiveUsers = () => {
	const { loading, data } = useOverviewContext();
	const rows: ITableRow[] =
		data?.userActivities?.map((node: any) => {
			return {
				id: node.username,
				values: [node.username, node.lastLogin, node.logins],
			} as ITableRow;
		}) || [];

	return (
		<InfoCard loading={loading}>
			<Table
				columns={mostActiveUsers}
				rows={rows || []}
				loading={loading}
			/>
		</InfoCard>
	);
};

export default MostActiveUsers;
