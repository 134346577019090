import { useQuery } from "@apollo/client";
import {
	GetMonthlyOverviewQuery,
	GetMonthlyOverviewQueryVariables,
	GetOverviewQuery,
	GetOverviewQueryVariables,
} from "./graphql/types";
import { GetOverview, GetMonthlyOverview } from "./query/leads";

interface Filter {
	companyId: number;
}

export const useOverview = ({ companyId }: Filter) => {
	const today = new Date();
	const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

	const previousMonthFrom = new Date(
		today.getFullYear(),
		today.getMonth() - 1,
		1,
	);
	const previousMonthTo = new Date(today.getFullYear(), today.getMonth(), 1);

	const allTime = useQuery<GetOverviewQuery, GetOverviewQueryVariables>(
		GetOverview,
		{
			variables: {
				condition: {
					companyId,
				},
			},
			errorPolicy: "all",
		},
	);

	const monthToDate = useQuery<
		GetMonthlyOverviewQuery,
		GetMonthlyOverviewQueryVariables
	>(GetMonthlyOverview, {
		variables: {
			condition: {
				companyId,
			},
			from: firstDay,
		},
		errorPolicy: "all",
	});

	const previousMonth = useQuery<
		GetMonthlyOverviewQuery,
		GetMonthlyOverviewQueryVariables
	>(GetMonthlyOverview, {
		variables: {
			condition: {
				companyId,
			},
			from: previousMonthFrom,
			to: previousMonthTo,
		},
		errorPolicy: "all",
	});

	return {
		data: {
			allTime: allTime.data,
			monthToDate: monthToDate.data,
			previousMonth: previousMonth.data,
		},
		loading: allTime.loading || monthToDate.loading,
		error: allTime.error || monthToDate.error,
	};
};
