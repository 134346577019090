import React, { Fragment, useMemo } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import isNil from "lodash/isNil";

import { Table } from "~/components/Table";
import { Typography } from "~/components/Typography";

interface ICriticalErrorProps {
	message: string;
	error: string;
}

const useStyles = makeStyles(() => ({
	overrideDisplay: {
		display: "flex",
		padding: "5%",
		flexFlow: "column nowrap",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		textAlign: "center",
	},
}));

const DisplayOverride: React.FC = ({ children }) => {
	const styles = useStyles();
	return <Box className={styles.overrideDisplay}>{children}</Box>;
};

export const CriticalError: React.FC<ICriticalErrorProps> = ({
	message,
	error,
}) => (
	<DisplayOverride>
		<Typography variant="h2" component="div">
			{message}
		</Typography>
		<Typography variant="body1" color="error">
			{error}
		</Typography>
	</DisplayOverride>
);
