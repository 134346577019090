import React from "react";
import { OverviewProvider } from "./_lib/context";
import OverviewDisplay from "./_lib/Display";

import { ViewComponent, createOverviewView } from "./_lib/view";

const Overview: ViewComponent = (props) => {
	return (
		<OverviewProvider>
			<OverviewDisplay {...props} />
		</OverviewProvider>
	);
};

export default createOverviewView(Overview, {
	title: "Overview",
});
