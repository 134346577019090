import React, { Fragment } from "react";

import { useOverviewContext } from "../../context";

import MultipleInfoCard from "../MultipleInfoCard";

import equifaxLogo from "./powered-by-equifax.png";
import cbbLogo from "./cbb-logo.png";

const AllTimeLeads = () => {
	const {
		loading,
		data: { leads },
	} = useOverviewContext();
	const columns = [
		{
			title: "Verified Credit Leads",
			quantity: leads?.allTime.credit,
			image: equifaxLogo,
		},
		{
			title: "Sell / Trade Leads",
			quantity: leads?.allTime.trade,
			image: cbbLogo,
		},
	];
	return (
		<Fragment>
			<MultipleInfoCard loading={loading} data={columns} title="Leads" />
		</Fragment>
	);
};

export default AllTimeLeads;
