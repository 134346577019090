import React from "react";

import { InfoCard } from "~/components/InfoCard";
import { ITableColumn, ITableRow, Table } from "~/components/Table";

import { useOverviewContext } from "../../context";

const TopReferrals = ({ type }: { type: "all" | "credit" | "trade" }) => {
	const { loading, data } = useOverviewContext();
	const name = type.charAt(0).toUpperCase() + type.slice(1);

	const topReferrals: ITableColumn[] = [
		{
			label: `Top ${name} Pages`,
			property: "url",
			width: "auto",
			cell: (props) => <a href={props} target="_blank">{props.length > 50 ? props.slice(0, 50) + "..." : props}</a>,
		},
		{
			label: `${name} Leads`,
			property: "count",
			width: "auto",
		},
	];

	const rows: ITableRow[] =
		data?.sources?.[type]?.map((node: any) => {
			return {
				id: node.url,
				values: [node.url, node.count],
			} as ITableRow;
		}) || [];

	return (
		<InfoCard loading={loading}>
			<Table
				columns={topReferrals}
				rows={rows || []}
				loading={loading}
			/>
		</InfoCard>
	);
};

export default TopReferrals;
