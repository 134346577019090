import React, { Fragment } from "react";

import { useOverviewContext } from "../../context";

import MultipleInfoCard from "../MultipleInfoCard";

const Status = () => {
	const {
		loading,
		data: { leads },
		isPreviousMonth,
	} = useOverviewContext();

	const columns = [
		{
			title: "New",
			quantity: isPreviousMonth
				? leads?.previousMonth?.status.newStatusLead
				: leads?.monthToDate?.status.newStatusLead,
		},
		{
			title: "Working",
			quantity: isPreviousMonth
				? leads?.previousMonth?.status.workingStatusLead
				: leads?.monthToDate?.status.workingStatusLead,
		},
		{
			title: "Contacted",
			quantity: isPreviousMonth
				? leads?.previousMonth?.status.contactedStatusLead
				: leads?.monthToDate?.status.contactedStatusLead,
		},
		{
			title: "Appointment Booked",
			quantity: isPreviousMonth
				? leads?.previousMonth?.status.appointmentBookedStatusLead
				: leads?.monthToDate?.status.appointmentBookedStatusLead,
		},
		{
			title: "Sold",
			quantity: isPreviousMonth
				? leads?.previousMonth?.status.soldStatusLead
				: leads?.monthToDate?.status?.soldStatusLead,
		},
	];

	return (
		<Fragment>
			<MultipleInfoCard
				loading={loading}
				data={columns}
				title="Lead Status"
			/>
		</Fragment>
	);
};

export default Status;
