import React from "react";

import { IconButton } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import { makeStyles } from "@material-ui/core/styles";

interface IPrint {
	onClick: () => void;
}

const useStyles = makeStyles((theme) => {
	return {
		title: {
			display: "flex",
			flexFlow: "row nowrap",
			alignItems: "center",
			gap: 8,
			[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
				display: "none",
			},
		},
		printButton: {
			height: 48,
			"@media print": {
				display: "none",
			},
		},
	};
});

const Print = ({ onClick }: IPrint) => {
	const styles = useStyles();

	return (
		<>
			<IconButton className={styles.printButton} onClick={onClick}>
				<PrintIcon />
			</IconButton>
		</>
	);
};

export default Print;
