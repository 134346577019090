import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	leadsOverviewHeader: {
		display: "flex",
		gap: 8,
		justifyContent: "space-between",
		width: "100%",
		alignItems: "center",
	},
	button: {
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			display: "none",
		},
		"@media print": {
			display: "none",
		},
	},
}));
