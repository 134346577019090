import React, { useMemo } from "react";

import { InfoCard } from "~/components/InfoCard";
import { Typography } from "~/components/Typography";
import { Loading } from "~/components/Loading";

import Box from "@material-ui/core/Box";

import { useMultipleCardStyles } from "../../common/styles";

import { IMultipleInfoCard } from "./types";

const MultipleInfoCard = ({ data, title, loading }: IMultipleInfoCard) => {
	const styles = useMultipleCardStyles();

	const content = useMemo(() => {
		if (loading) {
			return <Loading />;
		}

		return (
			<>
				{data?.map((d: any, index: number) => {
					return (
						<Box key={index} className={styles.multipleContent}>
							<Box className={styles.info}>
								<Typography className={styles.quantity}>
									{d?.quantity}
								</Typography>
								<Typography>{d?.title}</Typography>
								{!!d?.image && <img src={d?.image} style={{ width: 120 }} />}
							</Box>
						</Box>
					);
				})}
			</>
		);
	}, [data, loading, styles.info, styles.multipleContent, styles.quantity]);

	return (
		<InfoCard loading={loading} title={title}>
			<Box className={styles.content}>{content}</Box>
		</InfoCard>
	);
};

export default MultipleInfoCard;
