import React, { useMemo, useRef } from "react";

import dayjs from "dayjs";

import { useReactToPrint } from "react-to-print";

import { useAuth } from "@api/auth";

import { useOverviewContext } from "../context";

import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";

import { Typography } from "~/components/Typography";

import MostActiveUsers from "../components/MostActiveUsers";
import TopReferrals from "../components/TopReferrals";
import LeadsUsage from "../components/LeadsUsage";
import Leads from "../components/Leads";
import { CriticalError } from "../components/CriticalError";
import CreditScore from "../components/CreditScore";
import AllTimeLeads from "../components/AllTimeLeads";
import Status from "../components/Status";
import Print from "../components/Print";
import Expenses from "../components/Expenses";
import EstimatedRevenue from "../components/EstimatedRevenue";
import TopSocialTraffic from "../components/TopSocialTraffic";

import { customStyles } from "./styles";
import { Redirect } from "~/components/Redirect";

const OverviewDisplay: React.FC = () => {
	const { user, selectedCompany } = useAuth();
	const styles = customStyles();
	const { isPreviousMonth } = useOverviewContext();

	const getLastMonth = () => {
		const today = new Date();
		today.setDate(1);
		const lastMonth = today.setMonth(today.getMonth() - 1);

		return dayjs(lastMonth).format("MMMM");
	};
	const previousMonth = getLastMonth();

	const componentRef = useRef(null);

	const printStyle = `{ size: landscape }`;

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: printStyle,
		documentTitle: `Overview - ${selectedCompany?.name}`,
	});

	const content = useMemo(() => {
		if (!user?.autocorpAdmin && !user?.companyAdmin) {
			return <Redirect to="/leads/" />;
		}

		if (!selectedCompany) {
			return (
				<CriticalError
					message={
						"Please select a company to view the Overview Data"
					}
					error={""}
				/>
			);
		}
		return (
			<Grid container spacing={4}>
				<Box className={styles.companyName}>
					<Typography variant="title">
						{selectedCompany?.name} Overview
					</Typography>
				</Box>
				<Grid className={styles.item} item xs={12}>
					<Box className={styles.monthAndPrint}>
						<h2 className={styles.h2}>
							{isPreviousMonth ? previousMonth : "Month to Date"}
						</h2>
						<Print onClick={handlePrint} />
					</Box>
				</Grid>
				<Grid className={styles.item} item xs={12}>
					<Leads />
				</Grid>
				<Grid className={styles.item} item xs={12}>
					<Status />
				</Grid>
				<Grid className={styles.item} item xs={12}>
					<h2 className={styles.h2}>All Time</h2>
				</Grid>
				<Grid className={styles.item} item xs={12} md={6}>
					<CreditScore />
				</Grid>
				<Grid className={styles.item} item xs={12} md={6}>
					<AllTimeLeads />
				</Grid>
				<Grid className={styles.item} item xs={12} md={2}>
					<LeadsUsage type="all" />
				</Grid>
				<Grid className={styles.item} item xs={12} md={2}>
					<LeadsUsage type="credit" />
				</Grid>
				<Grid className={styles.item} item xs={12} md={2}>
					<LeadsUsage type="trade" />
				</Grid>
				<Grid className={styles.item} item xs={12} md={6}>
					<MostActiveUsers />
				</Grid>

				{/* <Grid item xs={12} md={3}>
					<EstimatedRevenue />
				</Grid>
				<Grid item xs={12} md={6}>
					<Expenses />
				</Grid> */}

				<Grid className={styles.item} item xs={12} md={6}>
					<TopReferrals type="credit" />
				</Grid>
				<Grid className={styles.item} item xs={12} md={6}>
					<TopReferrals type="trade" />
				</Grid>
				{/* <Grid item xs={12} md={6}>
					<TopSocialTraffic />
				</Grid> */}
			</Grid>
		);
	}, [
		selectedCompany,
		user?.autocorpAdmin,
		isPreviousMonth,
		user?.companyAdmin,
	]);

	return (
		<div ref={componentRef} className={styles.content}>
			{content}
		</div>
	);
};

export default OverviewDisplay;
