import { makeStyles } from "@material-ui/core";
import { FlexTableColumnDef } from "~/components/FlexTable/types";

const defaultColumns = ({
	col1,
	col2,
}: {
	col1?: React.CSSProperties;
	col2?: React.CSSProperties;
} = {}): FlexTableColumnDef[] => [
		{
			width: "20%",
			style: col1,
		},
		{
			width: "80%",
			style: col2,
		},
	];

export const rowCardColumnsFixedLong = defaultColumns({
	col1: {
		width: "70%",
	},
	col2: {
		fontWeight: "normal",
		justifyContent: "end",
	},
});

export const useSingleCardStyles = makeStyles(({ typography }) => ({
	content: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "nowrap",
		justifyContent: "center",
		alignItems: "center",
		padding: 24,
		textAlign: "center",
		wordBreak: "break-word",
		height: "100%",
	},
	quantity: {
		...typography.title,
		lineHeight: "44px",
	},
}));

export const useMultipleCardStyles = makeStyles(
	({ typography, breakpoints }) => ({
		content: {
			display: "flex",
			flexWrap: "nowrap",
			justifyContent: "space-around",
			alignItems: "center",
			padding: 24,
			textAlign: "center",
			[breakpoints.down(breakpoints.values.sm)]: {
				flexDirection: "column",
				padding: 0,
			},
		},
		quantity: {
			...typography.title,
			lineHeight: "44px",
		},
		multipleContent: {
			width: "fill-available",
			borderRight: "1px solid #B5B5B5",
			"&:last-child": {
				borderRight: "none",
			},
			[breakpoints.down(breakpoints.values.sm)]: {
				border: "none",
			},
		},
		info: {
			padding: 16,
			wordBreak: "break-word",
			display: "flex",
			flexDirection: "column",
			justifyContent: "start",
			alignItems: "center",
		},
	}),
);
